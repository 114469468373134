import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "../line-chart.css";
import { sortDates } from "../../../helpers";

//const dates = userResults.map((test) => parseDate(test.dateWritten));

const AverageHistory = ({ userResults }) => {
    const chartRef = useRef();

    useEffect(() => {
        // Parse the dates to valid JavaScript Date objects
        const parseDate = d3.timeParse("%d/%m/%Y");
        const yaxis = userResults.map((test) => test["overall-average"]);

        // Dates you want to display on the x-axis
        const dates = [
            parseDate('13/01/2024'),
            parseDate('13/11/2024'),
            parseDate('13/12/2024'),
        ];

        // Prepare chart dimensions and margins
        const width = 375;
        const height = 300;
        const margin = { top: 20, right: 30, bottom: 50, left: 40 };

        // Clear previous chart if it exists
        d3.select(chartRef.current).select("svg").remove();

        // Create the SVG container
        const svg = d3
            .select(chartRef.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        // Use scaleBand to create evenly spaced ticks for the dates
        const xScale = d3
            .scaleBand()
            .domain(dates) // Use the specific dates as categories
            .range([margin.left, width - margin.right])
            .padding(0.1); // Padding between the ticks

        const yScale = d3
            .scaleLinear()
            .domain([0, 100]) // Min and max for y-axis
            .nice()
            .range([height - margin.bottom, margin.top]);

        // Create the axes
        const xAxis = d3.axisBottom(xScale)
            .tickFormat(d3.timeFormat("%d/%m/%Y")); // Format dates as "DD/MM/YYYY"
        
        const yAxis = d3
            .axisLeft(yScale)
            .tickFormat((d) => `${d}%`) // Add % symbol to y-axis ticks
            .ticks(5);

        // Append the axes to the SVG
        svg
            .append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(xAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("font-weight", "bold")
            .style("color", "rgb(91, 88, 88)")
            .style("text-anchor", "end")
            .attr("transform", "rotate(-45)"); // Rotate for better readability

        svg
            .append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(yAxis)
            .selectAll("text")
            .style("font-size", "10px")
            .style("font-weight", "bold")
            .style("color", "rgb(91, 88, 88)");

        // Define the line generator
        const line = d3
            .line()
            .x((d, i) => xScale(dates[i]) + xScale.bandwidth() / 2)
            .y((d) => yScale(d)) // Y-axis data
            .curve(d3.curveMonotoneX); // Smooth line

        // Add the line to the chart
        svg
            .append("path")
            .datum(yaxis)
            .attr("fill", "none")
            .attr("stroke", "rgb(255, 99, 132)")
            .attr("stroke-width", 2)
            .attr("d", line);

        // Optional: Add points to the line
        svg
            .selectAll("circle")
            .data(yaxis)
            .join("circle")
            .attr("cx", (d, i) => xScale(dates[i]) + xScale.bandwidth() / 2)
            .attr("cy", (d) => yScale(d))
            .attr("r", 3)
            .attr("fill", "rgb(255, 99, 132)")
            .attr("stroke", "black")
            .attr("stroke-width", 0.5);

        // Optional: Tooltip for points
        const tooltip = d3
            .select(chartRef.current)
            .append("div")
            .attr("class", "tooltip")
            .style("position", "absolute")
            .style("opacity", 0)
            .style("background-color", "rgba(0, 0, 0, 0.8)")
            .style("color", "white")
            .style("padding", "5px")
            .style("border-radius", "4px")
            .style("pointer-events", "none")
            .style("z-index", "10");

        svg
            .selectAll("circle")
            .on("mouseover", (event, d, i) => {
                tooltip.style("opacity", 1);
                tooltip
                    .html(`Date: ${d3.timeFormat("%d/%m/%Y")(dates[i])}<br>Value: ${d}%`)
                    .style("left", `${event.pageX + 10}px`)
                    .style("top", `${event.pageY - 20}px`);
            })
            .on("mouseout", () => {
                tooltip.style("opacity", 0);
            });
    }, [userResults]);

    return <div ref={chartRef} style={{ position: "relative" }}></div>;
};

export default AverageHistory;