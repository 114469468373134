import React from 'react';
import "./test-options.css";
import { IoNavigateOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Paths } from "../../../helpers";

// Look at this site https://ecomflow.com/ IBIS&trade;

const TestOptions = () => {
    const navigate = useNavigate();

    const tests = [
        {
            testId: "IBIS100 (free)",
            testName: "IBIS100 (Practice test)",
            price: "Free",
            includes: ["6 minute timed test to answer 6 questions.", "Access to the practice test leaderboard and statistics."],
            color: "grey",
            border: "3px solid rgb(215, 215, 215)",
            buttonBackground: "white",
            buttonTextColor: "blue",
        },
        {
            testId: "IBIS100",
            testName: "IBIS100",
            price: "€20",
            includes: ["100 minute timed test to answer 100 questions.", "Access to the IBIS leaderboard and IBIS statistics.", "Test result report on test completion.", "IBIS100 Certification on acheiving 70% or higher."],
            color: "blue",
            border: "3px solid blue",
            buttonBackground: "linear-gradient(to right, blue 0%, blue 50%, rgb(120, 120, 249) 100%)",
            buttonTextColor: "white",
        }
    ]

    const getStarted = () => {
        navigate(Paths.Home)
    }

    return (
        <div className="test-options-root">
            {
                tests.map(test => {
                    return (
                        <div className="test-option" style={{border: test.border}}>
                            <div className="test-option-top">
                                <div className="test-option-ibis-logo">
                                    {test.testId}
                                </div>
                                <div className="test-option-top-details">
                                    <h3 className="test-details-name">
                                        {test.testName}
                                    </h3>
                                    <div className="test-option-top-detials-pricing">
                                        <h1 className="test-price">
                                            {test.price}
                                        </h1>
                                        <p>EUR / once off</p>
                                    </div>
                                </div>
                            </div>
                            <div className="test-option-information">
                                <h3>
                                    This test incldues the following:
                                </h3>
                                {
                                    test.includes.map(include => {
                                        return (
                                            <div className="include-text">
                                                <p className="include-text-mark" style={{ border: `1px solid ${test.color}`, color: test.color }}>✓</p>
                                                <p className="include-text-text">{include}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="test-option-button">
                                <button onClick={getStarted} style={{background: test.buttonBackground, color: test.buttonTextColor}}>Get Started<IoNavigateOutline /></button>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}

export default TestOptions;
