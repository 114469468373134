import React, { useRef, useEffect, useState } from 'react';
import Onboarding from '../components/on-boarding';
import Stats from '../components/stats';
import './landing-page.css';
import UnauthorisedHeader from '../unauthorised-header/unauthorised-header';
import WhyTakeTheIBIS from '../components/why-take-the-ibis';
import LeaderboardInformation from '../components/leader-board-info';
import SignalCompetence from '../components/signal-competence';
import Contact from '../components/contact';
import TestOptions from '../components (new)/test-options/test-options';

// Look at this site https://ecomflow.com/

const LandingPage = () => {
  const whyTakeTheIBISRef = useRef(null);
  const leaderboardsRef = useRef(null);

  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setHidden(true);
      } else {
        setHidden(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToWhyTakeTheIBIS = () => {
    whyTakeTheIBISRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollToLeaderboards = () => {
    leaderboardsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="App">
      <UnauthorisedHeader 
        hidden={hidden}
        scrollToWhyTakeTheIBIS={scrollToWhyTakeTheIBIS} 
        scrollToLeaderboards={scrollToLeaderboards} 
      />
      <Onboarding />
      <TestOptions />
    </div>
  );
}

export default LandingPage;
