import React, { useState, useEffect } from "react";
import "./dashboard-performance.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { FaExternalLinkAlt } from "react-icons/fa";

const getUserPerformanceData = (generalTableData, allResults) => {
    const ibisRanking = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.rank ?? '-' : '-';
    const ibisAverage = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.score ?? '-' : '-';
    const certified = allResults["IBIS100-Leaderboard"] ? allResults["IBIS100-Leaderboard"].
        find(result => result.username === generalTableData.username)?.certified ?? false : false;

    const userPerformance = [];
    userPerformance.push({
        text: "IBIS Certified",
        icon: certified  ? "certified.png" : "not-certified.png",
        value: certified ? "Yes" : "No"
    });
    userPerformance.push({
        text: "Highest test score",
        icon: "test-mark.png",
        value: ibisAverage === "-" ? "-" : `${parseFloat(ibisAverage).toFixed(2)}%`,
    });
    userPerformance.push({
        text: "Ranking",
        icon: "leaderboard.png",
        value: ibisRanking,
    });
    return userPerformance;
}

const DashboardPerformance = ({ generalTableData, allResults }) => {
    const [overAllPerformance, setOverAllPerformance] = useState([
        { text: "Certifications awarded", icon: "certificate.png", value: "0" },
        { text: "Participation count", icon: "test-complete.png", value: "0" },
        { text: "Global average", icon: "average.png", value: "0" }
    ]);

    useEffect(() => {
        if (allResults["IBIS100-Graphs"] && Object.keys(allResults["IBIS100-Graphs"]["globalResult"]).length > 0) {
            const globalResult = allResults["IBIS100-Graphs"]["globalResult"];
            setOverAllPerformance([
                { text: "Certifications awarded", icon: "certificate.png", value: globalResult["certifiedCount"] + 10 },
                { text: "Participation count", icon: "test-complete.png", value: globalResult["participationCount"] + 150 },
                { text: "Global average", icon: "average.png", value: `${parseFloat(globalResult["overall-average"]).toFixed(2)}%` }
            ]);
        }
    }, [allResults])

    const userPerformance = getUserPerformanceData(generalTableData, allResults);

    const openCertification = () => {
        window.open(`https://ibis100-prod-certificationconstructibis100prodibis-blunnlqdmbda.s3.eu-central-1.amazonaws.com/${generalTableData.username}.html`, '_blank');
    }

    return (
        <div className="dashboard-performance-root">
            <div className="dashboard-performance-overall">
                <div className="dashboard-text-header-div-styling">
                    <h3 className="dashboard-text-h3-styling">Personal performance</h3>
                    <p className="dashboard-text-p-styling">Overview of you overall IBIS100 performance</p>
                </div>
                <div className="dashboard-performance-stats">
                    {
                        userPerformance.map(stat => {
                            return (
                                <div className="dashboard-performance-stat">
                                    <img src={`/dashboard-icons/${stat.icon}`} />
                                    <div className="dashboard-performance-stat-information">
                                        <p className="dashboard-text-p-styling">{stat.text}</p>
                                        <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                                    </div>
                                    {
                                        stat.text === "IBIS Certified" && stat.value === "Yes" ?
                                            <div className="dashboard-ibis-certified-link" onClick={openCertification}>
                                                <p>See certification</p>
                                                <FaExternalLinkAlt />
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="dashboard-performance-overall">
                <div className="dashboard-text-header-div-styling">
                    <h3 className="dashboard-text-h3-styling">Overall performance</h3>
                    <p className="dashboard-text-p-styling">Overview of you overall IBIS100 performance</p>
                </div>
                <div className="dashboard-performance-stats">
                    {
                        overAllPerformance.map(stat => {
                            return (
                                <div className="dashboard-performance-stat">
                                    <img src={`/dashboard-icons/${stat.icon}`} />
                                    <div className="dashboard-performance-stat-information">
                                        <p className="dashboard-text-p-styling">{stat.text}</p>
                                        <h3 className="dashboard-text-h3-styling">{stat.value}</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default DashboardPerformance;
