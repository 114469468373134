import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import "./score-bar-chart.css";

const ScoreBarChart = ({
  userAverage,
  globalAverage,
  username,
  country,
  countryAverage,
}) => {
  const chartRef = useRef();

  useEffect(() => {
    const width = 400;
    const height = 250;
    const marginTop = 20;
    const marginRight = 20;
    const marginBottom = 30;
    const marginLeft = 30;

    d3.select(chartRef.current).select("svg").remove();

    const data = [
      { label: username, value: userAverage / 10 },
      { label: "Global", value: globalAverage / 10 },
      { label: country, value: countryAverage / 10 },
    ];

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.label))
      .range([marginLeft, width - marginRight])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      .domain([0, 10])
      .range([height - marginBottom, marginTop]);

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    svg
      .append("g")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("x", (d) => x(d.label))
      .attr("y", (d) => y(d.value))
      .attr("height", (d) => y(0) - y(d.value))
      .attr("width", x.bandwidth())
      .attr("fill", "steelblue")
      .style("opacity", 0.8)
      .attr("stroke", "black")
      .on("mouseover", function (event, d) {
        tooltip.style("opacity", 1);
        const chartRect = chartRef.current.getBoundingClientRect();
        
        tooltip
          .html(`${d.label}: ${d.value}`)
          .attr("data-value", d.value * 10)
          .style("left", `${chartRect.left + x(d.label) + x.bandwidth() / 2 - tooltip.node().offsetWidth / 2}px`)
          .style("top", `${chartRect.top + y(d.value) - 30}px`)
          .style("font-size", "10px");
      })
      .on("mouseout", function () {
        tooltip.style("opacity", 0);
      });

    svg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(d3.axisBottom(x));

    svg
      .append("g")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y));

    // Tooltip creation
    const tooltip = d3
      .select(chartRef.current)
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("opacity", 0)
      .style("background-color", "rgba(0, 0, 0, 0.8)")
      .style("color", "white")
      .style("border-radius", "4px")
      .style("padding", "5px")
      .style("pointer-events", "none")
      .style("z-index", "10");

  }, [userAverage, globalAverage, username, country, countryAverage]);

  return <div ref={chartRef}></div>;
};

export default ScoreBarChart;
