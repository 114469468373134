import React, { useState } from "react";
import "./purchase-form.css";
import "../../../helpers/shared-css/dashboard-styling.css";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { IoNavigateOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Paths, fetchRequest } from '../../../helpers';
import Spinner from "../../../spinner/spinner";

const PurchaseForm = ({ idToken, setStripePayment, setClientSecret, setInError }) => {
    const [testAmount, setTestAmount] = useState(1);
    const [informationOpened, setInformationOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const changeTestAmount = (amount) => {
        if (amount === -1 && testAmount > 1) {
            setTestAmount(testAmount + amount);
        } else if (amount === 1) {
            setTestAmount(testAmount + amount);
        }
    }

    const changeInformationDisplay = () => {
        setInformationOpened(!informationOpened);
    }

    const openStripePayment = async () => {
        setIsLoading(true);
        const response = await fetchRequest(idToken, "createCheckoutSession", "POST", {
            quantity: testAmount,
        });
        console.log(response)
        if (response.status !== 200) {
            setIsLoading(false);
            navigate(Paths.BookATest);
            setInError(true);
        } else {
            const data = await response.json();
            setClientSecret(data.clientSecret);
            setStripePayment(testAmount * 20);
            setIsLoading(false);
        }
    }

    return (
        <div className="purchase-form-root">
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Order: IBIS100 Test</h3>
                <p className="dashboard-text-p-styling">Complete your order by selecting the amount of tests you wish to purchase.</p>
            </div>
            <div className="purchase-form-order-item">
                <div className="purchase-form-header">
                    <h3 className="dashboard-text-h3-styling" style={{ textAlign: 'start' }}>Order item</h3>
                </div>
                <div className="purchase-form-order-info">
                    <p className="order-info-logo">IBIS&trade;</p>
                    <div className="order-info-text">
                        <p className="dashboard-text-p-styling">test</p>
                        <p className="order-info-header">IBIS100 test</p>
                        <p className="dashboard-text-p-styling">Price: €20</p>
                    </div>
                    <div className="order-info-test-amount">
                        <button className="order-info-test-amount-button" onClick={() => changeTestAmount(-1)}>-</button>
                        <p className="order-info-header">{testAmount}</p>
                        <button className="order-info-test-amount-button" onClick={() => changeTestAmount(1)}>+</button>
                    </div>
                </div>
                <div className="order-total">
                    <h3 className="dashboard-text-h3-styling">Total: </h3>
                    <h3 className="dashboard-text-h3-styling">{`€${20 * testAmount}`}</h3>
                </div>
            </div>
            <div className="purchase-form-order-item">
                <div className="purchase-form-header"
                    style={{ borderBottom: informationOpened ? '1px solid rgb(186, 185, 185)' : 'none' }}
                >
                    <h3 className="dashboard-text-h3-styling" style={{ textAlign: 'start' }}>Order information</h3>
                    {
                        informationOpened ?
                            <FaChevronUp className="information-icon" onClick={changeInformationDisplay} />
                            :
                            <FaChevronDown className="information-icon" onClick={changeInformationDisplay} />
                    }
                </div>
                {
                    informationOpened ?
                        <div className="purchase-form-order-description">
                            <p className="dashboard-text-p-styling">What you are purchasing: </p>
                            <p className="dashboard-text-p-styling" style={{ marginLeft: '20px' }}>&bull; Multiple choice test</p>
                            <p className="dashboard-text-p-styling" style={{ marginLeft: '20px' }}>&bull; 100 minutes to answer 100 questions</p>
                            <p className="dashboard-text-p-styling" style={{ marginLeft: '20px' }}>&bull; Personal performance report after completing the test</p>
                            <p className="dashboard-text-p-styling" style={{ marginLeft: '20px' }}>&bull; IBIS100 certification on acheiving 70% or higher</p>
                        </div>
                        : null
                }
            </div>
            <div className="dashboard-text-header-div-styling">
                <h3 className="dashboard-text-h3-styling">Purchase test</h3>
                <p className="dashboard-text-p-styling">Please select your form of payment.</p>
            </div>
            <div className="purchase-form-buttons">
                <button onClick={!isLoading ? openStripePayment : null}>{!isLoading ? "Purchase with card details" : <Spinner />}{!isLoading ? <IoNavigateOutline /> : null}</button>
            </div>
        </div>
    );
};

export default PurchaseForm;
